import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { TECH_OWN_LIST } from '../../urls'
import { useMessage } from '../../hooks/message'
import TechOwnForm from './TechOwnForm'

export default function TechOwnCreate({ onCancel, categoryList, expenseReport, brandList }) {
    const products = usePostRequest({ url: TECH_OWN_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success, error } = await products.request({
            data
        })
        if (success) {
            expenseReport.request()
            onCancel()
        }
        if (error) showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Қўшиш</b>
            </h1>
            <TechOwnForm loading={products.loading} categoryList={categoryList} brandList={brandList}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}
