import React from 'react'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { INCOME_ITEM_DETAIL } from '../urls'
import IncomeDetailUpdate from './IncomeDetailUpdate'
import { maxLength } from '../utils/string'
import Button from './common/Button'
import { getDate } from '../utils/date'

export default function IncomeDetailItem({ item, index, expenseReport }) {
    const brandRemove = useDeleteRequest({ url: INCOME_ITEM_DETAIL.replace('{id}', item.id) })

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <IncomeDetailUpdate
            item={item}
            onSuccess={() => {
                expenseReport.request()
                setShowUpdateModal()
            }}
            onCancel={() => setShowUpdateModal()}
        />,
    )

    async function onDelete() {
        if (global.confirm('Сиз чинакамдан ўчиришни истайсизми?')) {
            await brandRemove.request()
            expenseReport.request()
        }
    }

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        }).format(value).replace(/,/g, ' ')
    }

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">{maxLength(item?.name, 24)}</td>
            <td className="is-size-6">{item?.count}</td>
            <td className="is-size-6" style={{ color: item?.status === 'Приход' ? 'green' : 'red' }}>
                {format(item?.price)} Сум
            </td>
            <td className="is-size-6" style={{ color: item?.status === 'Приход' ? 'green' : 'red' }}>
                {format(item.total)} Сум
            </td>
            <td className="is-size-6">{getDate(item?.date)}</td>
            <td>
                <div style={{ display: 'flex' }} className="has-text-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
