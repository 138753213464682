import React from 'react'
import { Form, Formik } from 'formik'
import { required } from '../../utils/validators'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'

export default function EmployeeForm({ onCancel, onSubmit, initialValues, loading }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            // january: '',
            // february: '',
            // march: '',
            // april: '',
            // may: '',
            // june: '',
            // july: '',
            // august: '',
            // september: '',
            // october: '',
            // november: '',
            // december: '',
            ...initialValues
        }}>
            <Form>

                <Input name="name" label="Ходим" validate={required} />
                {/* <Input name="january" label="Январь" validate={required} /> */}
                {/* <Input name="february" label="Февраль" validate={required} /> */}
                {/* <Input name="march" label="Март" validate={required} /> */}
                {/* <Input name="april" label="Апрель" validate={required} /> */}
                {/* <Input name="may" label="Май" validate={required} /> */}
                {/* <Input name="june" label="Июнь" validate={required} /> */}
                {/* <Input name="july" label="Июль" validate={required} /> */}
                {/* <Input name="august" label="Август" validate={required} /> */}
                {/* <Input name="september" label="Сентябрь" validate={required} /> */}
                {/* <Input name="october" label="Октябрь" validate={required} /> */}
                {/* <Input name="november" label="Ноябрь" validate={required} /> */}
                {/* <Input name="december" label="Декабрь" validate={required} /> */}
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
