import React from 'react'
import { useMessage } from '../../hooks/message'
import { usePutRequest } from '../../hooks/request'
import { TECH_DETAIL } from '../../urls'
import TechForm from './TechForm'

export default function TechUpdate({ onCancel, item }) {
    const [showMessage] = useMessage()

    const client = usePutRequest({ url: TECH_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data, action) {
        const { success, error } = await client.request({ data })

        if (success) {
            onCancel()
            action.resetForm()
        }
        if (error) showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
    }

    return (
        <div>
            <h1 className="title has-text-centered is-capitalized">
                <b>Ўзгартириш</b>
            </h1>
            <TechForm loading={client.loading} initialValues={item} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
