export function format(number) {
    if (typeof number === 'number') {
        return number.toLocaleString('fr') // Format the number in French locale
    }
    return number || 0 // Return 0 for null/undefined or the original value for non-numeric inputs
}
export function integersOnly(value) {
    return value.replace(/[^0-9]/gim, '')
}

export function normalizePhone(phone) {
    if (phone.length === 9) {
        return phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4')
    }
    return phone
}

export function formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
