import React from 'react'
import { Link } from 'react-router-dom'
import { useDeleteRequest } from '../../hooks/request'
import { CATEGORY_DETAIL } from '../../urls'
import { useModal } from '../../hooks/modal'
import EmployeeUpdate from './EmployeeUpdate'
import Button from '../../components/common/Button'
// import { format } from '../../utils/number'

export default function EmployeeItem({ item, category, index }) {
    const brandRemove = useDeleteRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <EmployeeUpdate
            category={category}
            item={item}
            onSuccess={() => {
                category.request()
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()}
        />,
    )

    async function remove() {
        if (global.confirm('Сизни чинакамдан ўчиришни истайсизми?')) {
            await brandRemove.request()
            await category.request()
        }
    }

    return (
        <tr>
            <td>{index + 1}</td>

            <td>
                <Link to={`/employee/salary/${item.id}`}>
                    {item.name}
                </Link>
            </td>

            {/* <td>{format(item.january)}/{format(item.salaryJanuary)}</td> */}
            {/* <td>{format(item.february)}/{format(item.salaryFebruary)}</td> */}
            {/* <td>{format(item.march)}/{format(item.salaryMarch)}</td> */}
            {/* <td>{format(item.april)}/{format(item.salaryApril)}</td> */}
            {/* <td>{format(item.may)}/{format(item.salaryMay)}</td> */}
            {/* <td>{format(item.june)}/{format(item.salaryJune)}</td> */}
            {/* <td>{format(item.july)}/{format(item.salaryJuly)}</td> */}
            {/* <td>{format(item.august)}/{format(item.salaryAugust)}</td> */}
            {/* <td>{format(item.september)}/{format(item.salarySeptember)}</td> */}
            {/* <td>{format(item.october)}/{format(item.salaryOctober)}</td> */}
            {/* <td>{format(item.november)}/{format(item.salaryNovember)}</td> */}
            {/* <td>{format(item.december)}/{format(item.salaryDecember)}</td> */}

            <td>
                <div className="has-text-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button onClick={remove} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
