import React from 'react'

import { useModal } from '../../hooks/modal'
import { useDeleteRequest } from '../../hooks/request'
import { SALARY_DETAIL } from '../../urls'
import SalaryUpdate from './SalaryUpdate'
import { getDate } from '../../utils/date'
import { maxLength } from '../../utils/string'
import Button from '../../components/common/Button'

export default function SalaryItem({ item, index, expenseReport }) {
    const productRemove = useDeleteRequest({ url: SALARY_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            expenseReport.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <SalaryUpdate
            clients={item}
            item={item}
            onCancel={() => {
                expenseReport.request()
                hideUpdateModal()
            }}
        />,
    )

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        }).format(value).replace(/,/g, ' ')
    }

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            {/* <td className="is-size-6">{getMonth(item?.month)}</td> */}
            <td className="is-size-6">{getDate(item?.date)}</td>
            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {item.status === 'income' ? `${format(item?.amount)}Сум` : null}
            </td>

            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {item.status === 'expenses' ? `${format(item?.amount)}Сум` : null}
            </td>
            {/* <td className="is-size-6">{(item?.createdAt)}</td> */}
            <td className="is-size-6">{item.status === 'expenses' ? `${maxLength(item?.comment, 24)}` : null} </td>
            <td>
                <div style={{ display: 'flex' }} className="has-text-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
