import { css, StyleSheet } from 'aphrodite'
import { NavLink, useRouteMatch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { getEnv } from '../utils/environment'
import AccountPanel from './AccountPanel'
import { usePostRequest } from '../hooks/request'
import { SIGN_IN } from '../urls'
import { signinModal } from '../utils/auth'

export default function Layout({ children, padding = true, className }) {
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const signIn = usePostRequest({ url: SIGN_IN })

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
        } else if (document.exitFullscreen) {
            document.exitFullscreen()
        }
    }

    useEffect(() => {
        const isModalOpen = localStorage.getItem('isModalOpen') === 'true'
        setModalOpen(isModalOpen)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError('')
        const user = JSON.parse(localStorage.getItem('user'))
        const data = { email: user.email, password }

        try {
            const { response, success } = await signIn.request({ data })

            if (success) {
                setModalOpen(false)
                localStorage.setItem('isModalOpen', 'false')
                signinModal(response)
                setPassword('')
            } else {
                throw new Error('Authentication failed')
            }
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    const handleIconClick = () => {
        setModalOpen(true)
        localStorage.setItem('isModalOpen', 'true')
    }

    return (
        <div>
            <div className={css(styles.sidebar, menu ? styles.desktopSidebar : styles.mobileSidebar, getEnv() === 'development' ? styles.developmentSidebar : null, getEnv() === 'staging' ? styles.stagingSidebar : null)}>
                <div style={{ marginTop: 20 }}>
                    <div style={{ display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <i className={cn('icon is-size-4 ion-md-expand', css(styles.icon))} onClick={toggleFullscreen} />
                        <i className={cn('icon is-size-4 ion-md-lock', css(styles.icon))} onClick={handleIconClick} />
                    </div>
                    <AccountPanel />
                </div>

                <i onClick={() => setMenu(false)} className={cn('icon ion-md-arrow-back is-size-4', css(styles.hiderIcon))} />

                <p className={css(styles.menuHeading)}>Меню</p>

                <ul className={css(styles.menu)}>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/earnings"
                            className={css(styles.link, path.startsWith('/earnings') ? styles.active : null)}>
                            <i className={cn('icon ion-md-cash', css(styles.icon))} />
                            Кирим \ Чиқим
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/expense-report"
                            className={css(styles.link, path.startsWith('/expense-report') ? styles.active : null)}>
                            <i className={cn('icon ion-md-document', css(styles.icon))} />
                            Даромадлар
                        </NavLink>
                    </li>

                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/tech"
                            className={css(styles.link, path.startsWith('/tech') ? styles.active : null)}>
                            <i className={cn('icon ion-md-calculator', css(styles.icon))} />
                            Техника
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/own-tech"
                            className={css(styles.link, path.startsWith('/own-tech') ? styles.active : null)}>
                            <i className={cn('icon ion-md-car', css(styles.icon))} />
                            Техника (Ўзимиз)
                        </NavLink>
                    </li>

                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/employee"
                            className={css(styles.link, path.startsWith('/employee') ? styles.active : null)}>
                            <i className={cn('icon ion-md-person-add', css(styles.icon))} />
                            Ходимлар
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/user"
                            className={cn(css(styles.link), { [css(styles.active)]: path.startsWith('/settings/user') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Созламалар
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)}
                            className={cn('icon ion-md-menu is-size-3', css(styles.showerIcon))} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
            {modalOpen && (
                <div className={css(styles.modal)}>
                    <div className={css(styles.modalContent)}>
                        <form onSubmit={handleSubmit}>
                            <h2>Введите пароль</h2>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className={css(styles.input)} placeholder="пароль" required />
                            {error && <p className={css(styles.error)}>{error}</p>}
                            <button type="submit" className={css(styles.submitButton)} disabled={loading}>
                                {loading ? 'Загрузка...' : 'Войти'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}
const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: '5',
        '::-webkit-scrollbar': { display: 'none' },
        '@media (max-height: 525px)': { height: 'calc(100vh - 100%)', overflowX: 'scroll' },
    },
    developmentSidebar: { background: 'RGBA(31, 38, 104)' },
    stagingSidebar: { background: 'RGBA(31, 38, 104)' },
    desktopSidebar: { '@media (max-width: 769px)': { transform: 'translateX(0)', transition: 'all 300ms' } },
    mobileSidebar: { '@media (max-width: 769px)': { transform: 'translateX(-14rem)', transition: 'all 300ms' } },
    icon: { margin: '0 0.3rem 0.9rem 0' },
    link: { color: 'RGBA(255, 255, 255, .6)' },
    logo: { margin: '3.4rem 0.1rem', width: '8rem' },
    menuHeading: { color: 'RGBA(255,255,255, .6)', margin: '0 0 1rem 0', fontSize: 22, fontWeight: 700 },
    menu: { margin: '0 0 3.1rem 0' },
    padding: { padding: '1.8rem' },
    container: { margin: '0 0 0 14rem', '@media (max-width: 769px)': { margin: '0' } },
    active: { fontWeight: 'bolder', color: 'white' },
    content: { minHeight: '100vh' },
    showerIcon: { '@media (min-width: 769px)': { display: 'none' } },
    hiderIcon: { transform: 'translate(9.6rem, -15rem)', color: 'white', '@media (min-width: 769px)': { display: 'none' } },
    background: { '@media (max-width: 769px)': { bottom: 0, left: 0, position: 'fixed', right: 0, top: 0, background: 'RGBA(0, 0, 0, .5)', zIndex: 4 } },
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: 'white',
        padding: '2rem',
        borderRadius: '8px',
        width: '300px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        border: 'none',
        background: 'transparent',
    },
    input: {
        width: '100%',
        padding: '0.5rem',
        margin: '1rem 0',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    submitButton: {
        width: '100%',
        padding: '0.5rem',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        margin: '0.5rem 0',
    },
})
