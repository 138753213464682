/* eslint-disable */

import * as XLSX from 'xlsx'
import { StyleSheet } from 'aphrodite'
import React, { useState } from 'react'
import { TECH_CATEGORY_LIST, TECH_OWN_LIST } from "../../urls";
import { useLoad } from "../../hooks/request";
import { useQueryParams } from "../../hooks/queryString";
import DateFilter from "../../components/common/DateFilter";
import Button from "../../components/common/Button";
import { useModal } from "../../hooks/modal";
import Table from "../../components/common/Table";
import Layout from "../../components/Layout";
import TechOwnCreate from "./TechOwnCreate";
import CustomFilterSelect from "../../components/common/CustomFilterSelect";
import TechOwnItem from "./TechOwnItem";

export default function TechOwn() {
    const [page, setPage] = useState(1)
    const [params, setParams] = useState({})

    const searchParam = useQueryParams()
    const expense = useLoad({
        url: TECH_OWN_LIST,
        params: { page, ...params, ...searchParam }
    }, [searchParam, page, params])
    const { response: subcategoryResponse } = useLoad({
        url: TECH_CATEGORY_LIST,
        params: { size: 1000 }
    },)
    const subcategoryItems = subcategoryResponse ? subcategoryResponse.results : []
    const productList = expense.response ? expense.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(<TechOwnCreate expenseReport={expense}
                                                                          onCancel={() => setShowCreateModal()}/>, styles.modal)

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        }).format(value).replace(/,/g, ' ');
    }

    console.log(productList[0])

    const exportToExcel = (response) => {
        const headers = [
            { text: 'ID', key: 'id' },
            { text: 'Статус', key: 'status' },
            { text: 'Категория', key: 'category' },
            { text: 'Масул Ходим', key: 'assigned' },
            { text: 'Сумма', key: 'amount' },
            { text: 'Изоҳ', key: 'comment' },
            { text: 'Сана', key: 'createdAt' },
        ]

        const data = response.results.map((item) => ({
            id: item.id,
            status: item.status === 'expenses' ? 'Чиқим' : 'Кирим',
            category: item.selectCategory?.name || '',
            subcategory: item.selectSubCategory?.name || '',
            assigned: item.selectAssigned?.username || '',
            amount: parseInt(item.amount, 10), // Ensure amount is an integer,
            comment: item.comment || '',
            createdAt: new Date(item.createdAt).toLocaleDateString('en-GB'),
        }))

        const totalRow = {
            id: '',
            status: '',
            category: '',
            assigned: 'ЖАМИ КИРИМ',
            amount: parseInt(response.totalIncomeAmount, 10),
            comment: '',
            createdAt: ''
        }
        const expensesRow = {
            id: '',
            status: '',
            category: '',
            assigned: 'ЖАМИ ЧИҚИМ',
            amount: parseInt(response.totalExpensesAmount, 10),
            comment: '',
            createdAt: ''
        }
        const balanceRow = {
            id: '',
            status: '',
            category: '',
            assigned: 'Баланс',
            amount: parseInt(response.balance, 10),
            comment: '',
            createdAt: ''
        }

        const rows = [headers.map((header) => header.text), ...data.map((item) => headers.map((header) => item[header.key])), ['', '', '', '', '', '', '', ''], Object.values(totalRow), Object.values(expensesRow), Object.values(balanceRow)]

        const ws = XLSX.utils.aoa_to_sheet(rows)

        // Apply border styles with 2px black borders
        const range = XLSX.utils.decode_range(ws['!ref'])
        const borderStyle = {
            top: { style: 'medium', color: { rgb: '000000' } },
            bottom: { style: 'medium', color: { rgb: '000000' } },
            left: { style: 'medium', color: { rgb: '000000' } },
            right: { style: 'medium', color: { rgb: '000000' } },
        }

        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: R, c: C })
                if (!ws[cellAddress]) ws[cellAddress] = { v: '' } // Create empty cells if needed
                ws[cellAddress].s = { border: borderStyle }
            }
        }

        // Set column widths
        const colWidths = headers.map((header, index) => {
            const maxLength = Math.max(...rows.map((row) => (row[index] ? row[index].toString().length : 0)))
            return { wch: maxLength + 2 }
        })
        ws['!cols'] = colWidths

        // Create workbook and append sheet
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Молия Маълумотлари')

        // Save to file
        XLSX.writeFile(wb, 'МолияМаълумотлари.xlsx')
    }

    return (
        <Layout>
            <div className="is-flex is-justify-content-space-between mb-3 is-align-content-center">
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <CustomFilterSelect setSearchParams={setParams} placeholder="Категория" name="category"
                                        options={subcategoryItems}/>

                    <DateFilter name="start_date"/>
                    <DateFilter name="end_date"/>
                    {/*<Button text="Excel" className="is-pulled-right is-success is-outlined"*/}
                    {/*        onClick={() => exportToExcel(expense.response)}/>*/}
                    <Button text="Қўшиш" className="button is-right is-link is-outlined" onClick={showCreateModal}/>
                </div>
            </div>

            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <div style={{ border: '1px solid green', padding: 10, color: 'green' }}>
                    <p>
                        <b>Кирим:</b>
                        <span
                            className="ml-2">{format(expense.response ? expense.response.totalIncomeAmount : 0)} Сум</span>
                    </p>
                </div>

                <div style={{ border: '1px solid red', padding: 10, color: 'red' }}>
                    <b>Чиқим:</b>
                    <span
                        className="ml-2">{format(expense.response ? expense.response?.totalExpensesAmount : 0)} Сум</span>
                </div>

                <div style={{ border: '1px solid blue', padding: 10, color: 'blue' }}>
                    <b>Баланс:</b>
                    <span className="ml-2">{format(expense.response ? expense.response.balance : 0)} Сум</span>
                </div>
            </div>

            <div className="mt-4 mb-4">
                <Table
                    loading={expense.loading}
                    items={productList}
                    columns={{
                        index: '№',
                        date: 'Сана',
                        subcategory: 'Масул Ходим',
                        status: 'Статус',
                        category: 'Бажарилган иш',
                        size: 'Ўлчами',
                        amount: 'Сумма',
                        tractor: 'Тракторчига 20%',
                        note: 'Изох',
                        buttons: ''
                    }}
                    totalCount={expense.response ? expense.response.count : 0}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <TechOwnItem expenseReport={expense} index={index} key={item.id}
                                                              item={item}/>}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
