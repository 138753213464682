import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function SettingsTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li style={{ cursor: 'pointer' }}
                    className={cn({ 'is-active': path.startsWith('/settings/user-profile') })}>
                    <NavLink to="/settings/user">Профил</NavLink>
                </li>
                <li style={{ cursor: 'pointer' }} className={cn({ 'is-active': path.startsWith('/settings/income') })}>
                    <NavLink to="/settings/income">Даромадлар</NavLink>
                </li>
                <li style={{ cursor: 'pointer' }}
                    className={cn({ 'is-active': path.startsWith('/settings/category') })}>
                    <NavLink to="/settings/category">Категория</NavLink>
                </li>

                <li style={{ cursor: 'pointer' }}
                    className={cn({ 'is-active': path.startsWith('/settings/tech-category') })}>
                    <NavLink to="/settings/tech-category">Тех-Категория</NavLink>
                </li>

                <li style={{ cursor: 'pointer' }}
                    className={cn({ 'is-active': path.startsWith('/users') })}>
                    <NavLink to="/users">Ходимлар</NavLink>
                </li>
            </ul>
        </div>
    )
}
