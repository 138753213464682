import React from 'react'
import { Form, Formik } from 'formik'
import Button from '../../../components/common/Button'
import Input from '../../../components/common/Input'
import { required } from '../../../utils/validators'
import Select from '../../../components/common/Select'
import { payment } from '../../../utils/types'

export default function TechCategoryForm({ onCancel, onSubmit, initialValues, loading }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', status: '', ...initialValues }}>
            <Form>
                <Select
                    empty
                    optionValue="value"
                    optionLabel="name"
                    name="status"
                    label="Статус"
                    options={payment}
                    disabled={loading}
                />

                <Input name="name" label="Категория" validate={required} />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
