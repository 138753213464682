import React, { useState } from 'react'
import EmployeeCreate from './EmployeeCreate'
import { useModal } from '../../hooks/modal'
import Button from '../../components/common/Button'
import EmployeeItem from './EmployeeItem'
import Table from '../../components/common/Table'
import Layout from '../../components/Layout'
import { useLoad } from '../../hooks/request'
import { EMPLOYEE_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'

export default function Employee() {
    const [page, setPage] = useState(1)
    const searchParam = useQueryParams()
    const category = useLoad({ url: EMPLOYEE_LIST, params: { page, ...searchParam, size: 1000 } }, [page, searchParam])

    const [showCreateModal, hideCreateModal] = useModal(
        <EmployeeCreate
            onSuccess={() => {
                hideCreateModal()
                category.request()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const categories = category.response ? category.response.results : []

    return (
        <Layout>
            <div className="columns">
                <div className="is-size-4 column">Ходимлар</div>

                <div className="column is-narrow">
                    <Button onClick={showCreateModal} text="Қўшиш" icon="add-outline"
                        className="is-pulled-right is-link is-outlined" />
                </div>
            </div>

            <Table
                loading={category.loading}
                items={categories}
                columns={{
                    index: '№',
                    category: 'Ходим',
                    buttons: ''
                }}
                totalCount={categories.response ? categories.response.count : 0}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => (
                    <EmployeeItem index={index} category={category} item={item}
                        key={item.id}
                    />
                )}
            />
            <br />
        </Layout>
    )
}
