import React from 'react'
import { useDeleteRequest } from '../../../hooks/request'
import { useModal } from '../../../hooks/modal'
import { getDateTime } from '../../../utils/date'
import Loader from '../../../components/common/Loader'
import TechCategoryUpdate from './TechCategoryUpdate'
import { TECH_CATEGORY_DETAIL } from '../../../urls'
import { getPayment } from '../../../utils/types'

export default function TechCategoryItem({ item, category, index }) {
    const brandRemove = useDeleteRequest({ url: TECH_CATEGORY_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <TechCategoryUpdate
            category={category}
            item={item}
            onSuccess={() => {
                category.request()
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()}
        />,
    )

    async function remove() {
        if (global.confirm('Сизни чинакамдан ўчиришни истайсизми?')) {
            await brandRemove.request()
            await category.request()
        }
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                {item.name}
            </td>
            <td>
                {getPayment(item.status)}
            </td>
            <td>{getDateTime(item.createdAt)}</td>
            <td>
                <div className="is-pulled-right">
                    {!brandRemove.loading ? <i onClick={remove} className="icon pointer ion-md-trash" />
                        : <Loader className="icon" />}

                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                </div>
            </td>
        </tr>
    )
}
