import React from 'react'
import Select from 'react-select'
import { useQueryParams } from '../../hooks/queryString'

export default function CustomFilterSelect({
    name,
    options = [],
    optionValueKey = 'id',
    optionLabelKey = 'name',
    setSearchParams,
    ...props
}) {
    const params = useQueryParams()
    const currentVal = options.find((opt) => String(opt[optionValueKey]) === params[name])

    const handleChange = (newVal) => {
        setSearchParams((prev) => ({ ...prev, [name]: newVal?.[optionValueKey] }))
    }

    return (
        <Select
            value={currentVal}
            onChange={handleChange}
            options={options}
            getOptionValue={(opt) => opt[optionValueKey]}
            getOptionLabel={(opt) => opt[optionLabelKey]}
            isClearable
            styles={{
                container: (base) => ({ ...base, minWidth: '12rem' }),
                control: (base) => ({ ...base, height: '40px' }),
                placeholder: (base) => ({ ...base, whiteSpace: 'nowrap' }),
                indicatorSeparator: (base) => ({ ...base, display: 'none' }),
                menu: (base) => ({ ...base, zIndex: 10 }),
            }}
            {...props}
            isMulti={false}
        />
    )
}
