import React from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'

export default function SalaryForm({ onCancel, onSubmit, loading, initialValues }) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                status: 'expenses',
                // month: '',
                comment: '',
                date: '',
                amount: '',
                assigned: '',
                year: new Date().getFullYear().toString(),
                ...initialValues,
            }}
        >
            <Form>
                <div className="columns">
                    <div className="column">
                        {/* <Select */}
                        {/*    optionValue="value" */}
                        {/*    optionLabel="name" */}
                        {/*    name="month" */}
                        {/*    label="Тип расхода" */}
                        {/*    options={month} */}
                        {/*    disabled={loading} */}
                        {/* /> */}

                        <Input
                            name="date"
                            type="date"
                            label="Сана"
                            validate={required}
                            disabled={loading}
                        />
                        <Input
                            name="amount"
                            type="number"
                            label="Сумма"
                            validate={required}
                            disabled={loading}
                        />

                        <Input
                            component="textarea"
                            name="comment"
                            label="Примечание"
                            validate={required}
                            disabled={loading}
                        />

                    </div>
                </div>

                {/* Buttons */}
                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Ёпиш"
                        className="is-danger"
                        disabled={loading}
                    />
                    &nbsp;&nbsp;
                    <Button
                        loading={loading}
                        text="Сақлаш"
                        icon="add-outline"
                        type="submit"
                        className="is-success"
                        disabled={loading}
                    />
                </div>
            </Form>
        </Formik>
    )
}
