import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import SettingsTabs from '../../../components/settings/SettingsTabs'
import Card from '../../../components/common/Card'
import Button from '../../../components/common/Button'
import Table from '../../../components/common/Table'
import { useModal } from '../../../hooks/modal'
import { useLoad } from '../../../hooks/request'
import UsersCreate from './UsersCreate'
import { useQueryParams } from '../../../hooks/queryString'
import UsersItem from './UsersItem'
import { USERS_LIST } from '../../../urls'

export default function Users() {
    const [page, setPage] = useState(1)
    const params = useQueryParams()

    const category = useLoad({ url: USERS_LIST, params: { page, ...params, size: 1000 } }, [page, params])

    const [showCreateModal, hideCreateModal] = useModal(
        <UsersCreate
            onSuccess={() => {
                hideCreateModal()
                category.request()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const categories = category.response ? category.response.results : []

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gap: '1rem' }}>
                <Card>
                    <div className="columns">
                        <div className="is-size-4 column">Ходимлар</div>

                        <div className="column is-narrow">
                            <Button onClick={showCreateModal} text="Қўшиш" icon="add-outline"
                                className="is-pulled-right is-link is-outlined" />
                        </div>
                    </div>

                    <Table
                        loading={categories.loading}
                        items={categories}
                        columns={{
                            index: '№',
                            name: 'Исм Фамиля',
                            category: 'Электран почта',
                            username: 'Username',
                            chatId: 'Telegram ID',
                            date: 'Сана',
                            buttons: ''
                        }}
                        totalCount={categories.response ? categories.response.count : 0}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => (
                            <UsersItem index={index} category={category} item={item}
                                key={item.id}
                            />
                        )}
                    />
                </Card>
                <br />
            </div>
        </Layout>
    )
}
