import React, { useState } from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'
import Select from '../../components/common/Select'
import { payment } from '../../utils/types'
import { useLoad } from '../../hooks/request'
import { CATEGORY_LIST } from '../../urls'

export default function ExpenseForm({ onCancel, onSubmit, loading, initialValues }) {
    const [selectCategory, setSelectCategory] = useState()

    // Fetch categories
    const { response: categoryResponse } = useLoad({ url: CATEGORY_LIST, params: { size: 1000 } })
    const categoryItems = categoryResponse ? categoryResponse.results : []

    // Fetch subcategories based on selected category
    const { response: subcategoryResponse, loading: subcategoryLoading } = useLoad({
        url: CATEGORY_LIST,
        params: { parent: selectCategory, size: 1000 }
    }, [selectCategory])
    const subcategoryItems = subcategoryResponse ? subcategoryResponse.results : []

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                status: '',
                note: '',
                amount: '',
                category: '',
                subcategory: '',
                ...initialValues,
            }}
        >
            {({ setFieldValue, values }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Select
                                optionValue="value"
                                optionLabel="name"
                                name="status"
                                label="Тип расхода"
                                options={payment}
                                disabled={loading}
                            />
                            {values.status === 'expenses' ? (
                                <>
                                    <Select
                                        validate={required}
                                        empty
                                        name="category"
                                        options={categoryItems.filter((item) => item.parent === null)}
                                        onChange={(e) => {
                                            const selectedCategoryId = e.target.value
                                            setSelectCategory(selectedCategoryId)
                                            setFieldValue('category', selectedCategoryId)
                                            setFieldValue('subcategory', '') // Clear subcategory when category changes
                                        }}
                                        label="Категория"
                                        disabled={loading}
                                    />

                                    <Select
                                        validate={required}
                                        empty
                                        name="subcategory"
                                        options={subcategoryItems}
                                        label="Подкатегория"
                                        disabled={loading || subcategoryLoading || subcategoryItems.length === 0}
                                        onChange={(e) => setFieldValue('subcategory', e.target.value)}
                                    />
                                </>
                            ) : null}

                            <Input
                                name="amount"
                                type="number"
                                step="0.01"
                                label="Цена поступление"
                                validate={required}
                                disabled={loading}
                            />

                            {values.status === 'expenses' ? (
                                <Input
                                    component="textarea"
                                    name="comment"
                                    label="Примечание"
                                    validate={required}
                                    disabled={loading}
                                />
                            ) : null}

                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="close-outline"
                            text="Ёпиш"
                            className="is-danger"
                            disabled={loading}
                        />
                        &nbsp;&nbsp;
                        <Button
                            loading={loading}
                            text="Сақлаш"
                            icon="add-outline"
                            type="submit"
                            className="is-success"
                            disabled={loading}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
