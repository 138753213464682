import React from 'react'

import { useModal } from '../../hooks/modal'
import { useDeleteRequest } from '../../hooks/request'
import { TECH_DETAIL } from '../../urls'
import TechUpdate from './TechUpdate'
import { getPayment } from '../../utils/types'
import { getDate } from '../../utils/date'
import { maxLength } from '../../utils/string'
import Button from '../../components/common/Button'

export default function TechItem({ item, index, expenseReport }) {
    const productRemove = useDeleteRequest({ url: TECH_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            expenseReport.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TechUpdate
            clients={item}
            item={item}
            onCancel={() => {
                expenseReport.request()
                hideUpdateModal()
            }}
        />,
    )

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        }).format(value).replace(/,/g, ' ')
    }
    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">{getDate(item?.date)}</td>
            <td className="is-size-6">{item?.selectAssigned.username}</td>
            <td className="is-size-6">{getPayment(item?.status)}</td>
            <td className="is-size-6">
                {item?.selectCategory.name ? `${item?.selectCategory.name}` : 'Кирим'}
            </td>
            <td>{item.size}</td>
            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {format(item?.amount)} Сум
            </td>
            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {item.status === 'income' ? `${format(item?.amount * 0.2)} Сум` : null}

            </td>
            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {item.status === 'income' ? `${format(item?.amount * 0.3)} Сум` : null}
            </td>
            <td className="is-size-6" style={{ color: item?.status === 'income' ? 'green' : 'red' }}>
                {item.status === 'income' ? `${format(item?.amount * 0.5)} Сум` : null}
            </td>
            <td className="is-size-6">{maxLength(item?.comment, 24)}</td>
            <td>
                <div style={{ display: 'flex' }} className="has-text-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
