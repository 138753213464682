import React from 'react'

import EmployeeForm from './EmployeeForm'
import { EMPLOYEE_LIST } from '../../urls'
import { usePostRequest } from '../../hooks/request'

export default function EmployeeCreate({ onSuccess, onCancel }) {
    const brandCreate = usePostRequest({ url: EMPLOYEE_LIST })

    async function onSubmit(data) {
        const { success } = await brandCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Қўшиш</b>
            </h1>

            <EmployeeForm loading={brandCreate.loading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}
