import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { LanguageProvider } from './contexts/LanguageContext'
import Login from './pages/Login'
import ProtectedRoute from './components/common/ProtectedRoute'
import SettingsUser from './pages/settings/SettingsUser'
import Currency from './pages/settings/Income'
import Expenses from './pages/expenses/Expenses'
import Home from './pages/Home'
import Earnings from './pages/earnings/earnings'
import Category from './pages/settings/category/category'
import Calculator from './pages/calculator/Calculator'
import TechCategory from './pages/settings/tech-category/TechCategory'
import Tech from './pages/tech/Tech'
import Salary from './pages/salary/Salary'
import Users from './pages/settings/users/Users'
import Employee from './pages/employee/Employee'
import TechOwn from './pages/own/TechOwn'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <LanguageProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <ProtectedRoute path="/home" component={Home} exact />
                        <ProtectedRoute path="/expense-report" component={Expenses} exact />
                        <ProtectedRoute path="/tech" component={Tech} exact />
                        <ProtectedRoute path="/own-tech" component={TechOwn} exact />
                        <ProtectedRoute path="/employee/salary/:employeeId" component={Salary} exact />
                        <ProtectedRoute path="/employee" component={Employee} exact />
                        <ProtectedRoute path="/earnings" component={Earnings} exact />
                        <ProtectedRoute path="/calculator" component={Calculator} exact />
                        <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                        <ProtectedRoute path="/settings/income" component={Currency} exact />
                        <ProtectedRoute path="/settings/category" component={Category} exact />
                        <ProtectedRoute path="/settings/tech-category" component={TechCategory} exact />
                        <ProtectedRoute path="/users" component={Users} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </LanguageProvider>
    )
}
