/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames'
import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'

import { SIGNOUT } from '../urls'
import { signOut } from '../utils/auth'
import { useDeleteRequest } from '../hooks/request'

export default function AccountPanel() {
    const [showDropdown, setShowDropdown] = useState(false)
    const user = JSON.parse(localStorage.user)
    const history = useHistory()
    const signout = useDeleteRequest({ url: SIGNOUT })

    function logOut() {
        signout.request({}, true)
        signOut(history)
    }

    return (
        <div className={cn('dropdown', css(styles.dropdown), { 'is-active': showDropdown })} onMouseLeave={() => setShowDropdown(false)}>
            <div>
                <i className={cn('icon ion-md-contact is-size-1', css(styles.iconAccount))} />

                <div className={css(styles.account)} onClick={() => setShowDropdown(!showDropdown)}>
                    <p className={cn('pointer', css(styles.nameAccount))}>{user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email}</p>

                    <i className="icon ion-md-arrow-dropdown" />
                </div>
            </div>

            <div className="dropdown-menu">
                <div className="dropdown-content">
                    <a onClick={() => history.push('/settings/user-profile')} className="dropdown-item pointer">
                        <i className="icon ion-md-settings" />
                        Настройки
                    </a>

                    <a onClick={logOut} className="dropdown-item pointer">
                        <i className="icon ion-md-exit" />
                        Выйти
                    </a>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    iconAccount: { width: '2.5rem', transform: 'translateX(2.5rem)', margin: '0px auto 1rem auto' },
    dropdown: { marginBottom: '3.1rem' },
    nameAccount: { maxWidth: '9rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    account: { display: 'flex', flex: '1 1 0' },
})
