import React, { useState } from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'
import Select from '../../components/common/Select'
import { payment } from '../../utils/types'
import { useLoad } from '../../hooks/request'
import { TECH_CATEGORY_LIST } from '../../urls'

export default function TechOwnForm({ onCancel, onSubmit, loading, initialValues }) {
    const [status, setStatus] = useState()
    const { response: categoryResponse } = useLoad({
        url: TECH_CATEGORY_LIST,
        params: { size: 1000, status }
    }, [status])
    const categoryItems = categoryResponse ? categoryResponse.results : []

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                status: '',
                note: '',
                amount: '',
                date: '',
                category: '',
                size: '',
                ownWork: true,
                ...initialValues,
            }}
        >
            {({ values, setFieldValue }) => (

                <Form>
                    <div className="columns">
                        <div className="column">
                            <Select
                                empty
                                onChange={(e) => {
                                    const selectedValue = e.target.value // Get the selected value from the event
                                    setStatus(selectedValue) // Update the local status state
                                    setFieldValue('status', selectedValue) // Update the Formik field value
                                }}
                                optionValue="value"
                                optionLabel="name"
                                name="status"
                                label="Тип расхода"
                                options={payment}
                                disabled={loading}
                            />

                            <Input
                                name="date"
                                type="date"
                                label="Сана"
                                validate={required}
                                disabled={loading}
                            />
                            <Select
                                validate={required}
                                empty
                                name="category"
                                options={categoryItems}
                                label="Категория"
                                disabled={loading}
                            />
                            <Input
                                name="amount"
                                type="number"
                                label="Сумма"
                                validate={required}
                                disabled={loading}
                            />
                            {values.status === 'income' ? (
                                <Input
                                    name="size"
                                    type="number"
                                    label="Ўлчам"
                                    validate={required}
                                    disabled={loading}
                                />
                            ) : null}

                            <Input
                                component="textarea"
                                name="comment"
                                label="Примечание"
                                validate={required}
                                disabled={loading}
                            />

                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="close-outline"
                            text="Ёпиш"
                            className="is-danger"
                            disabled={loading}
                        />
                        &nbsp;&nbsp;
                        <Button
                            loading={loading}
                            text="Сақлаш"
                            icon="add-outline"
                            type="submit"
                            className="is-success"
                            disabled={loading}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
